<template>
  <div class="cpane-combox cpane-admincon">
    <div class="combox-contitle"><i class="icon-briefcase-full iconfont icon-title"></i>行政类数据</div>
    <div class="combox-conmain">
      <div class="conmain-mainitem">
        <span class="mainitem-itemname">所属行业</span>
        <div class="mainitem-itemdetail mainitem-itemindustry">
          <div class="itemdetail-cselect">
            <el-radio-group class="cselect-cradiogroup"
                            v-model="classifyRadio"
                            @change="industryChange">
              <el-radio class="cradiogroup"
                        label="国民经济行业分类"
                        :border="false"></el-radio>
              <el-radio class="cradiogroup"
                        label="战略性新兴产业分类"
                        :border="false"></el-radio>
            </el-radio-group>
          </div>
          <div class="itemdetail-cdetail">
            <!-- <el-select :class="['cdetail-marketsector', 'cdetail-industrybox', natEconomyObj.industryKeyVal ? 'warning-hintbg' : '']" ref="selects" v-model="adminObj.industry" @change="indChange" placeholder="输入所属行业">
							<el-option-group
								v-for="(group, index) in indDatas"
								:key="index">
								<el-option
									v-for="item in group.options"
									:key="item.id"
									:label="item.id + ' ' + item.name"
									:value="item.name">
								</el-option>
							</el-option-group>
						</el-select> -->
            <el-select :class="['cdetail-marketsector', 'cdetail-industrybox', natEconomyObj.industryKeyVal ? 'warning-hintbgblue' : '']"
                       ref="selects"
                       v-if="classifyRadio=='国民经济行业分类'"
                       v-model="typeIndustryVal1"
                       @change="indChange"
                       placeholder="输入所属行业">
              <el-option-group v-for="(group, index) in indDatas"
                               :key="index">
                <el-option v-for="item in group.options"
                           :key="item.id"
                           :label="item.id + ' ' + item.name"
                           :value="item.name">
                </el-option>
              </el-option-group>
            </el-select>
            <el-select :class="['cdetail-marketsector', 'cdetail-industrybox', natEconomyObj.industryKeyVal ? 'warning-hintbgblue' : '']"
                       ref="selects"
                       v-else
                       v-model="typeIndustryVal2"
                       @change="indChange"
                       placeholder="输入所属行业">
              <!--adminObj.industry-->
              <el-option-group v-for="(group, index) in indDatas"
                               :key="index">
                <el-option v-for="item in group.options"
                           :key="item.id"
                           :label="item.id + ' ' + item.name"
                           :value="item.name">
                </el-option>
              </el-option-group>
            </el-select>
            <el-input class="cdetail-iptkeyword"
                      v-model="indKeyWord"
                      @keyup.enter.native="keyWordSearch"
                      placeholder="使用关键词搜索"></el-input>
            <i class="icon-search iconfont icon-search"
               @click="keyWordSearch"></i>
          </div>
          <span class="hint-msg">企业的所属行业，可按照国民经济行业分类或战略性新兴产业分类来选择。注意这两个分类标准只能选择其一，以最终停留并确认提交的分类结果为准。</span>
          <span class="hint-msg">企业业务涉及多个行业分类的，请填写企业技术创新活动最为集中的分类。</span>
          <span class="hint-msg">所属行业对测评结果的准确性有较大影响，请谨慎填写。</span>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname">所属省份</span>
        <div class="mainitem-itemdetail"
             style="position:relative">
          <div class="itemdetail-cdetail">
            <!-- <el-form-item label="" prop="employee_count"> -->
            <!-- <el-input class="cdetail-iptstyle getAdminValue" v-model.number="adminObj.employee_count" placeholder="100"></el-input> -->
            <el-input :class="['cdetail-iptstyle', 'getAdminValue', adminTrueShow.devShowCount ? 'warning-hintbg' : '']"
                      v-model="adminObj.province"
                      readonly
                      @click.native="showMatrix"
                      @blur="showMatrix"
                      placeholder="北京"></el-input>
            <!-- </el-form-item> -->
          </div>
          <span class="hint-msg">企业注册地所归属的省（自治区、直辖市）。请按照最新情况做出选择。</span>
          <ul class="province-ul"
              v-show="provinced">
            <li v-for="item in provinceList"
                :key="item._id"
                :data-id="item._id"
                @mousedown="enterProvince(item)">{{item.value}}</li>
          </ul>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname">职工总数</span>
        <div class="mainitem-itemdetail">
          <div class="itemdetail-cdetail">
            <el-input :class="['cdetail-iptstyle', 'getAdminValue', adminTrueShow.employeeShowCount ? 'warning-hintbg' : '', natEconomyObj.employeeKeyCount ? 'warning-hintbgblue' : '']"
                      v-model="adminObj.employee_count"
                      placeholder="100"
                      @input="adminObj.employee_count = checkNumber($event, adminObj.employee_count)"
                      @blur="adminObj.employee_count = clearNumber(adminObj.employee_count)"></el-input>
            人
          </div>
          <span class="hint-msg">当年年末企业职工总数，含在职、兼职和临时聘用人员，其中兼职、临时聘用人员全年须在企业累计工作183天以上。</span>
          <span class="hint-msg hint-red">【注意】“当年”指企业前1个会计年度，下同。比如当前填写日期为2021年11月10日，则“当年”指2020.1.1~2020.12.31。</span>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname">研发人数</span>
        <div class="mainitem-itemdetail">
          <div class="itemdetail-cdetail">
            <el-input :class="['cdetail-iptstyle', 'getAdminValue', adminTrueShow.devShowCount ? 'warning-hintbg' : '']"
                      v-model="adminObj.dev_count"
                      placeholder="80"
                      @input="adminObj.dev_count = checkNumber($event, adminObj.dev_count)"
                      @blur="adminObj.dev_count = clearNumber(adminObj.dev_count)"></el-input>
            人
          </div>
          <span class="hint-msg">当年年末企业的研发人员数量。</span>
          <span class="hint-msg">研发人员指企业内部从事研发活动的人员，包括直接参加研发项目活动的人员，研发项目管理人员，以及为研发活动提供资料文献、材料供应、设备维护等直接服务的人员。其中兼职、临时聘用研发人员全年须在企业累计工作183天以上。</span>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname"><span class="dis-block">硕士以上</span><span class="dis-block m-t-5">研发人数</span></span>
        <div class="mainitem-itemdetail">
          <div class="itemdetail-cdetail">
            <el-input :class="['cdetail-iptstyle', 'getAdminValue', adminTrueShow.masterShowDevCount ? 'warning-hintbg' : '']"
                      v-model="adminObj.master_dev_count"
                      placeholder="50"
                      @input="adminObj.master_dev_count = checkNumber($event, adminObj.master_dev_count)"
                      @blur="adminObj.master_dev_count = clearNumber(adminObj.master_dev_count)"></el-input>
            人
          </div>
          <span class="hint-msg">当年年末企业研发人员中硕士及以上人员数量，含硕士、博士、中级技术职称、高级技术职称对应人数等。高级技术职称含：高级工程师、正副教授、正副研究员等；中级技术职称含：工程师、讲师、助理研究员等。</span>
          <span class="hint-msg hint-red">【注意】同一人同时符合学历和技术职称要求的，只计1人，不要重复统计。</span>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname"><span class="dis-block">研发人员</span><span class="dis-block">薪酬收入</span></span>
        <div class="mainitem-itemdetail">
          <div class="itemdetail-cdetail">
            <el-input :class="['cdetail-iptstyle', 'getAdminValue', adminTrueShow.devShowPayment ? 'warning-hintbg' : '']"
                      v-model="adminObj.dev_payment"
                      placeholder="1000.00"
                      @input="adminObj.dev_payment = checkFloat($event, adminObj.dev_payment)"
                      @blur="adminObj.dev_payment = clearFloat(adminObj.dev_payment)"></el-input>
            万元
          </div>
          <span class="hint-msg">当年企业研发人员薪酬总收入，含月度薪资、项目奖金、年底分红等。</span>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname"><span class="dis-block">职工薪酬</span><span class="dis-block">总收入</span></span>
        <div class="mainitem-itemdetail">
          <div class="itemdetail-cdetail">
            <el-input :class="['cdetail-iptstyle', 'getAdminValue', adminTrueShow.employeeShowPayment ? 'warning-hintbg' : '']"
                      v-model="adminObj.employee_payment"
                      placeholder="1500.00"
                      @input="adminObj.employee_payment = checkFloat($event, adminObj.employee_payment)"
                      @blur="adminObj.employee_payment = clearFloat(adminObj.employee_payment)"></el-input>
            万元
          </div>
          <span class="hint-msg">当年企业全部职工的薪酬总收入，含月度薪资、项目奖金、年底分红等。</span>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname"><span class="dis-block">职工教育培训</span><span class="dis-block">经费支出</span></span>
        <div class="mainitem-itemdetail">
          <div class="itemdetail-cdetail">
            <el-input :class="['cdetail-iptstyle', 'getAdminValue', adminTrueShow.educationShowCost ? 'warning-hintbg' : '']"
                      v-model="adminObj.education_cost"
                      placeholder="1500.00"
                      @input="adminObj.education_cost = checkFloat($event, adminObj.education_cost)"
                      @blur="adminObj.education_cost = clearFloat(adminObj.education_cost)"></el-input>
            万元
          </div>
          <span class="hint-msg">当年企业职工教育培训经费支出总额。</span>
          <span class="hint-msg">职工教育培训经费支出含上岗和转岗培训，各类岗位适应性培训，岗位培训、职业技术等级培训、高技能人才培训，专业技术人员继续教育，特种作业人员培训，企业组织的职工外送培训的经费支出，职工参加的职业技能鉴定、职业资格认证等经费支出，购置教学设备与设施，职工岗位自学成才奖励费用，职工教育培训管理费用，及有关职工教育的其他开支。</span>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname">高新技术企业</span>
        <div class="mainitem-itemdetail">
          <div class="itemdetail-cdetail">
            <el-radio-group class="cdetail-radiobox cdetail-hightech"
                            v-model="adminObj.hitech"
                            @change="hitechChange">
              <el-radio-button label="是"></el-radio-button>
              <el-radio-button label="否"></el-radio-button>
            </el-radio-group>
          </div>
          <span class="hint-msg">是否为有效期内的国家级、省级高新技术企业。</span>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname">上市企业</span>
        <div class="mainitem-itemdetail">
          <div class="itemdetail-cdetail">
            <el-radio-group class="cdetail-radiobox cdetail-marketenter"
                            v-model="adminObj.cpp">
              <el-radio-button label="是"></el-radio-button>
              <el-radio-button label="否"></el-radio-button>
            </el-radio-group>
            <el-select class="cdetail-marketsector"
                       v-model="adminObj.sto_sector"
                       placeholder="-请选择上市板块-">
              <el-option v-for="item in adminOptions.sectorOptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
            <el-input class="cdetail-iptstyle"
                      v-model="adminObj.sto_code"
                      placeholder="请填写股票代码"></el-input>
          </div>
          <span class="hint-msg">是否为上市机构及其上市板块、股票代码。</span>
        </div>
      </div>
      <!-- 添加更多 -->
      <div class="conmain-mainitem">
        <span class="mainitem-itemname"><span class="dis-block">省部级以上</span><span class="dis-block">研发平台</span></span>
        <div class="mainitem-itemdetail">
          <div class="itemdetail-cdetail">
            <div class="cdetail-cdetinput">
              <div class="cdetail-addbox">
                <div class="addbox-multiple"
                     v-for="(item, index) in adminObj.province_platforms"
                     :key="index">
                  <el-input class="cdetail-iptstyle"
                            v-model="item.name"
                            placeholder="平台名称"
                            @input="proPlatChange"></el-input>
                  <el-select class="cdetail-marketsector cdetail-deveplatform"
                             v-model="item.level"
                             @change="proPlatChange">
                    <el-option v-for="item in adminOptions.platformOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                  <i class="icon-jiahao1 iconfont cdetail-iconstyle"
                     v-if="index == 0"
                     @click="addPlatformHandle"></i>
                  <i class="icon-jianhao iconfont cdetail-iconjian"
                     v-if="index != 0"
                     @click="delPlatformHandle(index)"></i>
                </div>
              </div>
            </div>
          </div>
          <span class="hint-msg">当年年末企业拥有的省部级以上研发平台名称和等级。包括国家(省、部)重点实验室、国家(省、部)工程技术研究中心、国家(省、部)工程实验室、国家(省、部)工程研究中心、国家(省、部)企业技术中心、国家(省、部)国际联合研究中心等。</span>
          <span class="hint-msg">企业拥有多个研发平台的，点击”+“号可添加更多。</span>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname"><span class="dis-block">国际/行业</span><span class="dis-block">标准制定</span></span>
        <div class="mainitem-itemdetail">
          <div class="itemdetail-cdetail">
            <div class="cdetail-cdetinput">
              <div class="cdetail-addbox">
                <div class="addbox-multiple"
                     v-for="(item, index) in adminObj.industry_standards"
                     :key="index">
                  <el-input class="cdetail-iptstyle"
                            v-model="item.name"
                            placeholder="标准名称"
                            @input="proPlatChange"></el-input>
                  <el-select class="cdetail-marketsector cdetail-deveplatform"
                             v-model="item.category"
                             @change="proPlatChange">
                    <el-option v-for="item in adminOptions.categoryOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                  <el-select class="cdetail-marketsector cdetail-deveplatform"
                             v-model="item.action"
                             @change="proPlatChange">
                    <el-option v-for="item in adminOptions.actionOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                  <i class="icon-jiahao1 iconfont cdetail-iconstyle"
                     v-if="index == 0"
                     @click="addStandardHandle"></i>
                  <i class="icon-jianhao iconfont cdetail-iconjian"
                     v-if="index != 0"
                     @click="delStandardHandle(index)"></i>
                </div>
              </div>
            </div>
          </div>
          <span class="hint-msg">近三年内企业主导或参与制定，已公布且目前仍有效执行的国际、国家、行业标准。</span>
          <span class="hint-msg">请填写标准名称、级别，以及企业在标准制定过程中的地位，多个项目请分别填写。</span>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname"><span class="dis-block">省部级以上</span><span class="dis-block">科研项目</span></span>
        <div class="mainitem-itemdetail">
          <div class="itemdetail-cdetail">
            <div class="cdetail-cdetinput">
              <div class="cdetail-addbox">
                <div class="addbox-multiple"
                     v-for="(item, index) in adminObj.province_projects"
                     :key="index">
                  <el-input class="cdetail-iptstyle"
                            v-model="item.name"
                            placeholder="项目名称"
                            @input="proPlatChange"></el-input>
                  <el-input class="cdetail-iptstyle input-w150"
                            v-model="item.cost"
                            placeholder="1000.00"
                            @input="item.cost = checkFloat($event, item.cost)"
                            @blur="item.cost = clearFloat(item.cost)"></el-input>
                  <span class="cdetail-iptsunit">万元</span>
                  <el-select class="cdetail-marketsector cdetail-deveplatform"
                             v-model="item.category"
                             @change="proPlatChange">
                    <el-option v-for="item in adminOptions.platformOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                  <el-select class="cdetail-marketsector cdetail-deveplatform"
                             v-model="item.level"
                             @change="proPlatChange">
                    <el-option v-for="item in adminOptions.projectLevelOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                  <i class="icon-jiahao1 iconfont cdetail-iconstyle"
                     v-if="index == 0"
                     @click="addProjectHandle"></i>
                  <i class="icon-jianhao iconfont cdetail-iconjian"
                     v-if="index != 0"
                     @click="delProjectHandle(index)"></i>
                </div>
              </div>
            </div>
          </div>
          <span class="hint-msg">企业近三年承担国家或省部级以上科研项目立项类别及金额，如863研究项目等。</span>
          <span class="hint-msg">请填写项目名称、拨付经费、级别，以及企业是否为项目第一承担单位的信息，多个项目请分别填写。</span>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname"><span class="dis-block">省部级以上</span><span class="dis-block">科技奖励</span></span>
        <div class="mainitem-itemdetail">
          <div class="itemdetail-cdetail">
            <div class="cdetail-cdetinput">
              <div class="cdetail-addbox">
                <div class="addbox-multiple"
                     v-for="(item, index) in adminObj.province_rewards"
                     :key="index">
                  <el-input class="cdetail-iptstyle"
                            v-model="item.project_name"
                            placeholder="获奖项目名称"
                            @input="proPlatChange"></el-input>
                  <el-input class="cdetail-iptstyle input-w250"
                            v-model="item.name"
                            placeholder="奖励名称"
                            @input="proPlatChange"></el-input>
                  <el-select class="cdetail-marketsector cdetail-deveplatform"
                             v-model="item.category"
                             @change="proPlatChange">
                    <el-option v-for="item in adminOptions.platformOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                  <el-select class="cdetail-marketsector cdetail-deveplatform"
                             v-model="item.level"
                             @change="proPlatChange">
                    <el-option v-for="item in adminOptions.rewardLevelOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                  <i class="icon-jiahao1 iconfont cdetail-iconstyle"
                     v-if="index == 0"
                     @click="addRewardHandle"></i>
                  <i class="icon-jianhao iconfont cdetail-iconjian"
                     v-if="index != 0"
                     @click="delRewardHandle(index)"></i>
                </div>
              </div>
            </div>
          </div>
          <span class="hint-msg">企业近三年获得国家或省部级以上科技奖励类别及数量，如国家科学技术进步奖、国家技术发明奖等。</span>
          <span class="hint-msg">请填写获奖项目名称、奖励名称、获奖等级，以及企业是否为第一获奖单位的信息，多个项目请分别填写。</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      indKeyWord: '', // 所属行业-关键词搜索
      indDatas: [ // 行业列表
        // {id:'', text: '全部行业'},
      ],
      // provincePlatformCount: 1, // 省部级以上研发平台-添加更多-列
      // industryStandardCount: 1, // 国际/行业标准制定-添加更多-列
      // industryProjectCount: 1, // 省部级以上科研项目-添加更多-列
      // industryRewardCount: 1, // 省部级以上科技奖励-添加更多-列
      proPlatformCount: 1,
      classifyRadio: '国民经济行业分类', // 所属行业分类

      natEconomyObj: { // 是否缺失关键字，如果缺失关键字，设置底框颜色提示
        industryKeyVal: false,
        employeeKeyCount: false
      },

      typeIndustryVal1: '', // 国民经济分类--选中的所属行业
      typeIndustryVal2: '', // 战略新兴产业--选中的所属行业
      // 是否展示所有省份框
      provinced: false,
      // 所有的省市
      provinceList: []
    }
  },
  props: {
    adminObj: {
      type: Object,
      default () {
        return {}
      }
    },
    adminTrueShow: {
      type: Object,
      default () {
        return {}
      }
    },
    adminOptions: {
      type: Object,
      default () {
        return {}
      }
    },
    provincePlatformCount: {
      type: Number,
      default () {
        return 1
      }
    },
    industryStandardCount: {
      type: Number,
      default () {
        return 1
      }
    },
    industryProjectCount: {
      type: Number,
      default () {
        return 1
      }
    },
    industryRewardCount: {
      type: Number,
      default () {
        return 1
      }
    },
  },
  components: {

  },
  created () {
    this.keyWordSearch() // 关键字搜索获取行业列表
    // 获取所有省份
    this.getProvince()
  },
  updated () {
    this.$parent.$parent.$parent.isHaveNull()
  },
  methods: {
    getProvince () {
      this.$axios({
        url: '/company/find/province',
        method: 'get'
      }).then(res => {
        let { code, data } = res.data
        if (code == 200) {
          this.provinceList = data
          // this.provinceList.forEach(item => {
          //   if (item.value == this.adminObj.province) {
          //     this.adminObj.province = item._id
          //     this.adminObj.provinceName = item.value
          //   }
          // })
        } else {
          this.provinceList = []
        }
      })
    },
    industryChange () { // 所属行业类型选择
      console.log(this.classifyRadio)
      console.log(this.adminObj.industry)
      this.keyWordSearch() // 关键字搜索获取行业列表
      // this.adminObj.industryType = this.classifyRadio
      if (this.classifyRadio == '战略性新兴产业分类') {
        // this.adminObj.industryType = '战略性新兴产业分类'
        this.adminObj.industry = this.typeIndustryVal2
      } else {
        // this.adminObj.industryType = '国民经济行业分类'
        this.adminObj.industry = this.typeIndustryVal1
      }
      console.log('国民经济-' + this.typeIndustryVal1 + '----战略性新兴-' + this.typeIndustryVal2)
      console.log(this.adminObj.industry)
      // this.adminObj.industry = ''
      // this.noKeyData()
    },
    // 判断选择的是哪一个行业类型,判断是否缺失关键数据并作出提示
    noKeyData () {
      if (this.classifyRadio == '战略性新兴产业分类') { // 国民经济行业分类
        this.adminObj.industry = this.typeIndustryVal2
        console.log(this.adminObj)
        let natEconomyDatas = []
        this.natEconomyObj.employeeKeyCount = false // 职工总数
        if (this.adminObj.industry) { // 所属行业 // 如果有关键数据，则为false，反之无数据，则为true,设置底色红色显示
          natEconomyDatas.push(this.adminObj.industry)
          this.natEconomyObj.industryKeyVal = false
        } else {
          this.natEconomyObj.industryKeyVal = true
        }
        console.log(this.natEconomyObj)
        return natEconomyDatas
      } else {
        this.adminObj.industry = this.typeIndustryVal1
        // console.log(this.adminObj)
        let emerIndusDatas = []
        if (this.adminObj.employee_count) { // 职工总数
          emerIndusDatas.push(this.adminObj.employee_count)
          this.natEconomyObj.employeeKeyCount = false
          // employee_count: '', // 职工总数  operating_income: '', // 营业收入  total_assets: '', // 资产总额
        } else {
          this.natEconomyObj.employeeKeyCount = true
        }
        if (this.adminObj.industry) { // 所属行业
          emerIndusDatas.push(this.adminObj.industry)
          this.natEconomyObj.industryKeyVal = false
        } else {
          this.natEconomyObj.industryKeyVal = true
        }
        console.log(this.natEconomyObj)
        return emerIndusDatas
      }
    },
    hitechChange () {
      console.log(this.adminObj.hitech)
    },
    indChange (val) { // 获取所属行业
      console.log(val + '============' + this.adminObj.industry)
    },
    keyWordSearch () { // 所属行业-关键字搜索获取行业列表
      let _this = this
      let industryType = _this.classifyRadio == '战略性新兴产业分类' ? 2 : 1 // 行业类型 1-国民经济行业分类；2-战略性新兴产业分类
      console.log('行业分类-' + industryType)
      console.log('industryType - ' + industryType)
      let qsData = _this.$qs.stringify({
        industry: _this.indKeyWord,
        industryType: industryType,
      });
      _this.$axios({
        method: 'post',
        url: '/company/online/find/industry',
        data: qsData,
        // baseUrl: _this.urlCommon,
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      }).then(function (res) {
        // console.log(res)
        // console.log(res.data.code == 200)
        if (res.data.code == 401) {
          _this.goLoginPopupShow = true
          return
        }
        if (res.data.code == 200) {
          _this.indDatas = res.data.data
          if (_this.indKeyWord) {
            _this.$refs.selects.$el.click()
          }
        } else {
          _this.indDatas = []
        }
      }).catch(function (e) {
        console.log('error------------------------------error');
        console.log(e.response)
      });
    },
    proPlatChange () {
      this.$forceUpdate()
    },
    delPlatformHandle (index) {
      let _this = this;
      _this.adminObj.province_platforms.splice(index, 1)
      _this.$forceUpdate()
      console.log(_this.adminObj.province_platforms)
    },
    addPlatformHandle () { // 点击“+”添加更多-添加列-省部级以上研发平台
      let _this = this;
      // console.log(_this.provincePlatformCount)
      // _this.provincePlatformCount = _this.adminObj.province_platforms.length

      // _this.proPlatformCount = _this.provincePlatformCount
      // _this.proPlatformCount ++
      if (_this.adminObj.province_platforms.length < 5) {
        _this.adminObj.province_platforms.push({ name: '', level: '国家级' })
        _this.$forceUpdate()
      }
      // _this.$parent.$parent.$parent.isHaveNull()
      // console.log('-------点击“+”添加更多')
      // console.log(_this.adminObj.province_platforms)
    },
    delStandardHandle (index) {
      let _this = this;
      _this.adminObj.industry_standards.splice(index, 1)
      _this.$forceUpdate()
      console.log(_this.adminObj.industry_standards)
    },
    addStandardHandle () { // 点击“+”添加更多-添加列-国际/行业标准制定
      let _this = this
      // _this.industryStandardCount ++
      if (_this.adminObj.industry_standards.length < 5) {
        _this.adminObj.industry_standards.push({ name: '', category: '国际标准', action: '主导制定' })
        _this.$forceUpdate()
      }
      // console.log('-------点击“+”添加更多')
      // console.log(_this.adminObj.industry_standards)
    },
    delProjectHandle (index) {
      let _this = this;
      _this.adminObj.province_projects.splice(index, 1)
      _this.$forceUpdate()
      console.log(_this.adminObj.province_projects)
    },
    addProjectHandle () { // 点击“+”添加更多-添加列-省部级以上科研项目
      let _this = this
      // _this.industryProjectCount ++
      if (_this.adminObj.province_projects.length < 5) {
        _this.adminObj.province_projects.push({ name: '', cost: '', category: '国家级', level: '第一承担单位' })
        _this.$forceUpdate()
      }
      // console.log('-------点击“+”添加更多')
      // console.log(_this.adminObj.province_projects)
    },
    delRewardHandle (index) {
      let _this = this;
      _this.adminObj.province_rewards.splice(index, 1)
      _this.$forceUpdate()
      console.log(_this.adminObj.province_rewards)
    },
    addRewardHandle () { // 点击“+”添加更多-添加列-省部级以上科技奖励
      let _this = this
      // _this.industryRewardCount ++
      if (_this.adminObj.province_rewards.length < 5) {
        _this.adminObj.province_rewards.push({ project_name: '', name: '', category: '国家级', level: '第一获奖单位' })
        _this.$forceUpdate()
      }
      // console.log('-------点击“+”添加更多')
      // console.log(_this.adminObj.province_rewards)
    },

    // input控制输入内容
    checkNumber (e, val) { // 输入整数
      this.$forceUpdate()
      // val = (val.match(/^\d*/g)[0]) || null
      val = (val.match(/^\d{0,7}/g)[0]) || null
      return val
    },
    clearNumber (val) { // 保留小数点后两位，不满两位则补零
      this.$parent.$parent.$parent.verifyAdminValue()
      if (val) {
        return parseInt(val)
      } else {
        return val
      }
    },
    checkFloat (e, val) { // 最多输入两位小数
      this.$forceUpdate()
      val = val.toString()
      val = val.replace(/^\./g, "") // 必须保证第一个为数字而不是点
      // val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || null
      val = (val.match(/^\d{0,9}\.\d\d|^\d{0,9}\.\d|^\d{0,9}\.|^\d{0,9}/g)[0]) || null
      return val
    },
    clearFloat (val) { // 保留小数点后两位，不满两位则补零
      // console.log(val)
      this.$parent.$parent.$parent.verifyAdminValue()
      if (val) {
        val = val.toString()
        if (val.indexOf(".") < 0) {
          val = val + ".00"
        } else if (val.indexOf(".") > 0) {
          let decimalNum = val.split('.')[1]
          if (decimalNum != '') {
            if (decimalNum.length == 1) {
              val = val + "0"
            }
          } else {
            val = val + "00"
          }
        }
        return parseFloat(val).toFixed(2)
        // return val
      } else {
        return val
      }
    },
    // 展示所有省份
    showMatrix () {
      this.provinced = !this.provinced
    },
    // 点击某个省份后
    enterProvince (item) {
      this.adminObj.province = item.value
    }
  },
  watch: {
    adminObj: {
      handler (val, oldVal) {
        // console.log('当数量变化时重新绘制图表')//但是这两个值打印出来却都是一样的
        console.log(val + '---' + oldVal)
        if (this.adminObj.industryType) {
          this.classifyRadio = this.adminObj.industryType
        }
        // if(this.adminObj.industry) {
        this.typeIndustryVal2 = ''
        this.typeIndustryVal1 = ''
        if (this.adminObj.industryType == '战略性新兴产业分类') {
          this.typeIndustryVal2 = this.adminObj.industry
        } else {
          this.typeIndustryVal1 = this.adminObj.industry
        }
        // }
        this.keyWordSearch()
        console.log(this.adminObj.industry)
        console.log('国民经济-' + this.typeIndustryVal1 + '----战略性新兴-' + this.typeIndustryVal2)
      }
    },
  }
}
</script>

<style scoped>
/* tabs内容 */
.contle-ctabs /deep/ .el-tabs__content {
  padding: 0;
}
.cpane-combox {
  padding: 20px;
  box-sizing: border-box;
  background: #e4e4e4;
}
.cpane-admincon {
  text-align: left;
}
.combox-contitle {
  position: relative;
  padding-left: 35px;
  line-height: 30px;
  color: #000000;
  font-weight: 700;
  font-size: 22px;
}
.combox-contitle .icon-title {
  position: absolute;
  left: 0;
  font-size: 22px;
}
.combox-contitle .icon-fintitle {
  position: absolute;
  left: 0;
  font-size: 26px;
}
.combox-conmain {
}
.conmain-mainitem {
  position: relative;
  margin-top: 40px;
}
.conmain-mainitem:first-child {
  margin-top: 100px;
}
.conmain-mainitem::after {
  display: block;
  content: "";
  clear: both;
}
.mainitem-itemname {
  position: absolute;
  left: 0;
  width: 120px;
  line-height: 30px;
  text-align: right;
  color: #000000;
  font-weight: 700;
  font-size: 20px;
} /*input框标题字号*/
.dis-block {
  display: block;
}
.dis-block:last-child {
  margin-top: -5px;
}
.mainitem-itemdetail {
  position: relative;
  margin-left: 130px;
}
.itemdetail-cdetail {
  line-height: 30px;
  font-size: 16px;
}
.itemdetail-cdetail::after {
  display: block;
  content: "";
  clear: both;
}
.cdetail-iptbox {
  display: block;
  float: left;
  margin-right: 10px;
  width: 300px;
}
.cdetail-iptbox /deep/ .el-input__inner {
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  font-size: 16px;
}
.cdetail-iptkeyword {
  display: block;
  float: left;
  margin-right: 5px;
  width: 200px;
}
.cdetail-iptkeyword /deep/ .el-input__inner {
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  font-size: 16px;
}
.cdetail-cdetinput::after {
  display: block;
  content: "";
  clear: both;
}
/* 所属行业-分类 */
.mainitem-itemindustry {
  position: relative;
}
.itemdetail-cselect {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 40px;
}
/*  */
.itemdetail-cselect /deep/ .el-radio-group {
}
.itemdetail-cselect /deep/ .el-radio {
  margin: 0;
  width: 200px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(102, 102, 102);
  box-sizing: border-box;
  background: none;
  text-align: center;
  font-size: 16px;
}
.itemdetail-cselect /deep/ .el-radio:first-child::after {
  content: "|";
  position: absolute;
  right: 0;
}
.itemdetail-cselect /deep/ .el-radio.is-checked {
  border-bottom: 3px solid rgb(237, 173, 8);
}
.itemdetail-cselect /deep/ .el-radio__input {
  display: none;
}
.itemdetail-cselect /deep/ .el-radio__label {
  padding-left: 0;
  font-size: 16px;
}
.itemdetail-cselect /deep/ .el-radio.is-checked .el-radio__label {
  color: rgb(237, 173, 8);
}
/* .itemdetail-cselect .cselect-cradiogroph{border-bottom: 1px solid rgb(237, 173, 8);} */
/* .itemdetail-cselect /deep/ .el-radio-button{height: 40px;border-bottom: 1px solid rgb(102, 102, 102);box-sizing: border-box;} */
/* .itemdetail-cselect /deep/ .el-radio-button.is-active{border: none;border-bottom: 3px solid rgb(237, 173, 8);} */

/* 点击“+”号添加更多box-------------- */
.cdetail-addbox {
  display: block;
  float: left;
}
.cdetail-addbox::after {
  display: block;
  content: "";
  clear: both;
}
.addbox-multiple {
  margin-bottom: 10px;
}
.addbox-multiple:last-child {
  margin-bottom: 10px;
}
.addbox-multiple::after {
  display: block;
  content: "";
  clear: both;
}
/* 点击“+”号添加更多box-------------- */
.cdetail-iptstyle {
  display: block;
  float: left;
  margin-right: 10px;
  width: 300px;
}
.cdetail-iptstyle /deep/ .el-input__inner {
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  font-size: 16px;
}
.warning-hintbg /deep/ .el-input__inner {
  background: rgb(255, 204, 204);
}
.warning-hintbgblue /deep/ .el-input__inner {
  background: rgb(153, 180, 203);
}
.cdetail-iptstyle .el-input__inner::focus {
  border: 1px solid #409eff;
  background: #fff !important;
}
.icon-search {
  display: block;
  float: left;
  width: 60px;
  height: 30px;
  line-height: 30px;
  font-size: 24px;
}
.input-w150 {
  width: 150px;
}
.input-w250 {
  width: 250px;
}
.cdetail-iptsunit {
  display: block;
  float: left;
  margin-right: 20px;
}
.cdetail-radiobox {
  display: block;
  float: left;
  margin-right: 10px;
}
/* .cdetail-radiobox /deep/ .el-radio-button{background: transparent;} */
.cdetail-radiobox /deep/ .el-radio-button__inner {
  padding: 0 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid rgb(204, 204, 204);
  background: transparent;
  box-sizing: border-box;
  border-radius: 0;
  color: rgb(0, 0, 0);
  font-size: 16px;
}
.cdetail-radiobox
  /deep/
  .el-radio-button__orig-radio:checked
  + .el-radio-button__inner {
  background: rgb(241, 194, 50);
  border-color: rgb(241, 194, 50);
  box-shadow: -1px 0 0 0 rgb(241, 194, 50);
}
.cdetail-marketsector {
  display: block;
  float: left;
  margin-right: 10px;
  width: 180px;
}
.cdetail-industrybox {
  width: 400px;
}
.cdetail-marketsector /deep/ .el-input__inner {
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  font-size: 16px;
}
.cdetail-marketsector /deep/ .el-input__icon {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
}
.addbox-multiple .cdetail-iconjian {
  display: block;
  float: left;
  line-height: 30px;
  font-size: 26px;
  cursor: pointer;
}
.cdetail-iconstyle {
  display: block;
  float: left;
  line-height: 30px;
  font-size: 26px;
  cursor: pointer;
}

/* el-radio-button is-active */
/* 提示 */
.hint-msg {
  margin-top: 5px;
  display: block;
  color: #999999;
  font-weight: 400;
  font-size: 16px;
}
.hint-msg:first-child {
  margin-top: 5px;
}
.hint-red {
  color: rgb(255, 0, 0);
}
/* border: 1px solid #DCDFE6;box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%); */
/* 所有省份 */
.province-ul {
  width: 350px;
  position: absolute;
  top: 35px;
  z-index: 100;
  background: #ffffff;
  padding: 10px 2px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}
.province-ul > li {
  width: 25%;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
}
.province-ul .nobottom {
  margin-bottom: 0;
}
</style>
