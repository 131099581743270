<template>
	<div ref="echartBar" :style="{margin: '0 auto',width: '100%', height: '100%'}"></div>
</template>

<script>
	export default {
		data(){
			return{
				
			}
		},
		props:{
			barColor: {
				type: String,
				default() {
					return ''
				}
			},
			echartDatas: {
				type: Array,
				default() {
					return []
				}
			},
			maxVal: {
				type: Number,
				default() {
					return 100
				}
			}
		},
		components:{
	
		},
		created() {
			
		},
		mounted() {
			this.drawBar()
		},
		methods: {
			drawBar() {
				// this.$refs.echartBar.removeAttribute("_echarts_instance_");
				// document.getElementById('echartLine').removeAttribute("_echarts_instance_");
				// 基于准备好的dom，初始化echarts实例
				let myChart = this.$echarts.init(this.$refs.echartBar, 'light');
				let barDatas = this.echartDatas;
				// 指定图表的配置项和数据
				let option = {
					xAxis: {
						type: 'value',
						show: false,
						maxInterval: 1,
						min: 0,
						max: this.maxVal,
						// boundaryGap : [0, 1],
					},
					yAxis: {
						type: 'category',
						show: false,
						data: ['Mon']
					},
					grid: {
						left: 0,
						right: 0,
					},
					series: [
						{
							type: 'bar',
							barMaxWidth: 30,
							showBackground: true,
							backgroundStyle: {
								color: 'rgb(204, 204, 204)'
							},
							color: this.barColor,
							data: barDatas,
						}
					]
				};
	
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
			},
	
		},
		watch: {
			echartDatas: {
				handler(val, oldVal){
					// console.log('当数量变化时重新绘制图表')//但是这两个值打印出来却都是一样的
					console.log(val)
					console.log(oldVal)
					this.drawBar()
				}
			},
		}
	}
</script>

<style>
</style>
