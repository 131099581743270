<template>
	<div ref="echartGraph" :style="{margin: '0 auto',width: '100%', height: '100%'}"></div>
</template>

<script>
	export default {
		data(){
			return{
				
			}
		},
		props:{
			echartDatas: {
				type: Array,
				default() {
					return []
				}
			},
			graph: {
				type: Object,
				default() {
					return {}
				}
			},
			nodes: {
				type: Array,
				default() {
					return []
				}
			},
			links: {
				type: Array,
				default() {
					return []
				}
			},
		},
		components:{
	
		},
		mounted() {
			this.drawGraph()
		},
		methods: {
			drawGraph(){
				this.$refs.echartGraph.removeAttribute("_echarts_instance_");
				// document.getElementById('echartLine').removeAttribute("_echarts_instance_");
				// 基于准备好的dom，初始化echarts实例
				let myChart = this.$echarts.init(this.$refs.echartGraph, 'light');
				// 指定图表的配置项和数据
				console.log(this.nodes)
				console.log(this.links)
				let graph = {
					nodes: this.nodes,
					links: this.links,
				};
				// let defaultCategory = "中心颜色";
				let graphTitle = "";
				let currentGraph = {
					nodes: {},
					links: {},
				};
				let nodeMap = {};
				// 页面加载时，第一次初始化图
				function init() {
					// 根据定义的常量，产生currentGraph的默认数据
					// 遍历全部nodes和links，产生node映射map
					for (let i = 0; i < graph.nodes.length; i++) {
						// if (graph.nodes[i].category === defaultCategory) { // 初次渲染只渲染“中心颜色”的判断
							currentGraph.nodes[graph.nodes[i].name] = graph.nodes[i];
						// }
						Object.values(graph.links).forEach(l => {
							currentGraph.links[l.source + "_" + l.target] = l;
						});
						nodeMap[graph.nodes[i].name] = graph.nodes[i];
						nodeMap[graph.nodes[i].name]['links'] = {};
						nodeMap[graph.nodes[i].name]['nodes'] = {};
						nodeMap[graph.nodes[i].name]['hasAppend'] = false;
					}
					for (let i = 0; i < graph.links.length; i++) {
						let link = graph.links[i];
						if (nodeMap[link.source] !== undefined && nodeMap[link.target] !== undefined) {
							nodeMap[link.source].links[link.target] = link;
							nodeMap[link.source].nodes[nodeMap[link.target].name] = nodeMap[link.target];
						}
					}
					for (let i = 0; i < graph.nodes.length; i++) {
						graph.nodes[i].itemStyle = null;
						graph.nodes[i].label = {
							normal: {
								show: graph.nodes[i].symbolSize > 10
							}
						};
					}
					redrawGraph();
				}
				// 处理点击节点展开
				function append(nodeName) {
					// 根据nodeName从nodeMap里拿出对应的nodes和links，并append到currentGraph.nodes currentGraph.links
					let node = nodeMap[nodeName];
					if (node.hasAppend === false || Object.keys(node.nodes).length === 0 || Object.keys(node.links).length === 0) {
						alert("无法继续展开");
						return
					}
					Object.values(node.nodes).forEach(n => {
						currentGraph.nodes[n.name] = n;
					});
					Object.values(node.links).forEach(l => {
						currentGraph.links[l.source + "_" + l.target] = l;
					});
					node.hasAppend = false;
					redrawGraph();
				}
				// 处理点击节点收缩
				function remove(nodeName) {
					//根据nodeName从nodeMap里拿出对应的nodes和links，从currentGraph.nodes currentGraph.links删除当前节点的nodes和links并且递归
					let node = nodeMap[nodeName];
					Object.values(node.nodes).forEach(n => {
						delete currentGraph.nodes[n.name];
						if (Object.keys(n.nodes).length > 0) {
							remove(n.name);
						}
					});
					Object.values(node.links).forEach(l => {
						delete currentGraph.links[l.source + '_' + l.target];
					});
					// 设置flag 等于false
					node.hasAppend = true;

					redrawGraph();
				}
				// 根据更新后的option重新画图
				function redrawGraph() {
					option1.series[0].data = Object.values(currentGraph.nodes);
					option1.series[0].links = Object.values(currentGraph.links);
					console.log(option1);
					myChart.setOption(option1);
				}
				let option1 = {
					title: {
						text: graphTitle,
						top: 'top',
						left: 'center',
					},
					tooltip: {},
					legend: [],
					animation: false,
					series: [{
						type: 'graph',
						layout: 'force',
						data: Object.values(currentGraph.nodes),
						links: Object.values(currentGraph.links),
						categories: [
							{
								name: '中心颜色',
								itemStyle: {
									color: '#CC503E'
								},
							},
							{
								name: '创新投入颜色',
								itemStyle: {
									color: '#1D6996'
								},
							},
							{
								name: '知识产权颜色',
								itemStyle: {
									color: '#73AF48'
								},
							},
							{
								name: '创新效益颜色',
								itemStyle: {
									color: '#EDAD08'
								},
							},
							{
								name: '创新组织与管理颜色',
								itemStyle: {
									color: '#9F90BC'
								},
							},
							{
								name: '附加分颜色',
								itemStyle: {
									color: '#BE8261'
								},
							},
							{
								name: '无数据颜色',
								itemStyle: {
									color: '#CCCCCC'
								},
							},
						],
						roam: true,
						zoom: 1.3,
						focusNodeAdjacency: true,
						itemStyle: {
							normal: {
								borderColor: '#fff',
								borderWidth: 1,
								shadowBlur: 5,
								shadowColor: 'rgba(0, 0, 0, 0.3)'
							}
						},
						label: {
							position: 'right',
							formatter: '{b}',
							fontSize: 14
						},
						lineStyle: {
							color: 'target',
							opacity: 0.6,
							curveness: 0.3
						},
						emphasis: {
							lineStyle: {
								width: 5
							}
						},
						force: {
							layoutAnimation: true,
							edgeLength: 50, //连线的长度
							repulsion: 100 //子节点之间的间距
						},
						draggable: true, // 可拖拽
					}]
				};
				init();
				myChart.on('click', function(params) {
					if (params.dataType === "node") {
						const node = nodeMap[params.data.name];
						if (node.hasAppend === true) {
							append(node.name)
						} else {
							remove(node.name);
						}
					}
				});
			},
	
		},
		watch: {
			// echartDatas: {
			// 	handler(val, oldVal){
			// 		// console.log('当数量变化时重新绘制图表')//但是这两个值打印出来却都是一样的
			// 		this.drawGraph()
			// 	}
			// },
			nodes: {
				handler(val, oldVal){
					// console.log('当数量变化时重新绘制图表')//但是这两个值打印出来却都是一样的
					console.log(val)
					console.log(oldVal)
					this.drawGraph()
				}
			},
			links: {
				handler(val, oldVal){
					// console.log('当数量变化时重新绘制图表')//但是这两个值打印出来却都是一样的
					console.log(val)
					console.log(oldVal)
					this.drawGraph()
				}
			},
		}
	}
</script>

<style>
</style>
