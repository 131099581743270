<template>
	<!-- <div> -->
		<div class="cpane-combox cpane-admincon cpane-adminfinance">
			<div class="combox-contitle"><i class="icon-caiwu1 iconfont icon-title"></i>财务类数据</div>
			<div class="combox-conmain">
				<div class="conmain-mainitem">
					<span class="mainitem-itemname">研发经费支出</span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<span class="cdetail-namestyle">当年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getFinanceValue', financeTrueShow.currShowDevCost ? 'warning-hintbg' : '']" v-model="financeObj.curr_dev_cost" placeholder="1000.00" @input="financeObj.curr_dev_cost = checkFloat($event, financeObj.curr_dev_cost)" @blur="financeObj.curr_dev_cost = clearFloat(financeObj.curr_dev_cost)"></el-input>
							<span class="cdetail-namestyle">万元，上一年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getFinanceValue', financeTrueShow.prev1ShowDevCost ? 'warning-hintbg' : '']" v-model="financeObj.prev1_dev_cost" placeholder="1000.00" @input="financeObj.prev1_dev_cost = checkFloat($event, financeObj.prev1_dev_cost)" @blur="financeObj.prev1_dev_cost = clearFloat(financeObj.prev1_dev_cost)"></el-input>
							<span class="cdetail-namestyle">万元，上上年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getFinanceValue', financeTrueShow.prev2ShowDevDost ? 'warning-hintbg' : '']" v-model="financeObj.prev2_dev_cost" placeholder="1000.00" @input="financeObj.prev2_dev_cost = checkFloat($event, financeObj.prev2_dev_cost)" @blur="financeObj.prev2_dev_cost = clearFloat(financeObj.prev2_dev_cost)"></el-input>
							<span class="cdetail-namestyle">万元</span>
						</div>
						<span class="hint-msg">当年、上一年度及上上年度企业内部、外部研发经费支出的总额。</span>
						<span class="hint-msg">内部研发经费支出指企业在当年用于内部开展研发活动的实际支出，包括用于研发活动的直接支出，以及间接用于研发活动的管理费、服务费、与研发有关的基本建设支出以及外协加工费等；外部研发经费支出指当年企业委托外单位或与外单位合作进行研发活动而拨给对方的经费。</span>
						<span class="hint-msg hint-red">【注意】“当年”指企业前1个会计年度，下同。比如当前填写日期为2021年11月10日，则“当年”指2020.1.1~2020.12.31。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname">研发设备原值</span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-input :class="['cdetail-iptstyle', 'getFinanceValue', financeTrueShow.developShowDevicePrice ? 'warning-hintbg' : '']" v-model="financeObj.develop_device_price" placeholder="1000.00" @input="financeObj.develop_device_price = checkFloat($event, financeObj.develop_device_price)" @blur="financeObj.develop_device_price = clearFloat(financeObj.develop_device_price)"></el-input>
							万元
						</div>
						<span class="hint-msg">当年年末用于研发的固定资产中的仪器和设备的原价，不含租赁使用。</span>
						<span class="hint-msg">研发设备包括用于研发活动的各类机器和设备、试验测量仪器、运输工具、工装工具等。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname"><span class="dis-block">消化吸收</span><span class="dis-block">经费支出</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-input :class="['cdetail-iptstyle', 'getFinanceValue', financeTrueShow.consumeShowCost ? 'warning-hintbg' : '']" v-model="financeObj.consume_cost" placeholder="1000.00" @input="financeObj.consume_cost = checkFloat($event, financeObj.consume_cost)" @blur="financeObj.consume_cost = clearFloat(financeObj.consume_cost)"></el-input>
							万元
						</div>
						<span class="hint-msg">当年企业用于消化吸收的经费支出。</span>
						<span class="hint-msg">消化吸收经费支出指对引进技术的掌握、应用、复制而开展的工作，以及在此基础上的创新；消化吸收经费支出包括人员培训费、测绘费、参加消化吸收人员的工资、工装、工艺开发费、必备的配套设备费、翻版费等。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname"><span class="dis-block">技术改造</span><span class="dis-block">经费支出</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-input :class="['cdetail-iptstyle', 'getFinanceValue', financeTrueShow.techShowCost ? 'warning-hintbg' : '']" v-model="financeObj.tech_cost" placeholder="1000.00" @input="financeObj.tech_cost = checkFloat($event, financeObj.tech_cost)" @blur="financeObj.tech_cost = clearFloat(financeObj.tech_cost)"></el-input>
							万元
						</div>
						<span class="hint-msg">当年企业用于技术改造的经费支出。</span>
						<span class="hint-msg">技术改造经费支出是企业进行技术改造而发生的费用支出。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname"><span class="dis-block">知识产权</span><span class="dis-block">运营收入</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<span class="cdetail-namestyle">转让</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getFinanceValue', financeTrueShow.operateShowTransferCost ? 'warning-hintbg' : '']" v-model="financeObj.operate_transfer_cost" placeholder="1000.00" @input="financeObj.operate_transfer_cost = checkFloat($event, financeObj.operate_transfer_cost)" @blur="financeObj.operate_transfer_cost = clearFloat(financeObj.operate_transfer_cost)"></el-input>
							<span class="cdetail-namestyle">万元，许可</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getFinanceValue', financeTrueShow.operateShowLicenseCost ? 'warning-hintbg' : '']" v-model="financeObj.operate_license_cost" placeholder="1000.00" @input="financeObj.operate_license_cost = checkFloat($event, financeObj.operate_license_cost)" @blur="financeObj.operate_license_cost = clearFloat(financeObj.operate_license_cost)"></el-input>
							<span class="cdetail-namestyle">万元，质押</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getFinanceValue', financeTrueShow.operateShowPledgeCost ? 'warning-hintbg' : '']" v-model="financeObj.operate_pledge_cost" placeholder="1000.00" @input="financeObj.operate_pledge_cost = checkFloat($event, financeObj.operate_pledge_cost)" @blur="financeObj.operate_pledge_cost = clearFloat(financeObj.operate_pledge_cost)"></el-input>
							<span class="cdetail-namestyle">万元，其他</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getFinanceValue', financeTrueShow.operateShowOtherCost ? 'warning-hintbg' : '']" v-model="financeObj.operate_other_cost" placeholder="1000.00" @input="financeObj.operate_other_cost = checkFloat($event, financeObj.operate_other_cost)" @blur="financeObj.operate_other_cost = clearFloat(financeObj.operate_other_cost)"></el-input>
							<span class="cdetail-namestyle">万元</span>
						</div>
						<span class="hint-msg">当年该企业运营知识产权获得的收入。</span>
						<span class="hint-msg">知识产权运营包括知识产权的转让、许可、质押融资等，其收入包含当年得到的一次性付款和分期付款收入，以及利润分成、股息收入等。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname">新产品销售额</span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<span class="cdetail-namestyle">当年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getFinanceValue', financeTrueShow.curShowNewAmount ? 'warning-hintbg' : '']" v-model="financeObj.cur_new_amount" placeholder="1000.00" @input="financeObj.cur_new_amount = checkFloat($event, financeObj.cur_new_amount)" @blur="financeObj.cur_new_amount = clearFloat(financeObj.cur_new_amount)"></el-input>
							<span class="cdetail-namestyle">万元，上一年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getFinanceValue', financeTrueShow.prev1ShowNewAmount ? 'warning-hintbg' : '']" v-model="financeObj.prev1_new_amount" placeholder="1000.00" @input="financeObj.prev1_new_amount = checkFloat($event, financeObj.prev1_new_amount)" @blur="financeObj.prev1_new_amount = clearFloat(financeObj.prev1_new_amount)"></el-input>
							<span class="cdetail-namestyle">万元，上上年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getFinanceValue', financeTrueShow.prev2ShowNewAmount ? 'warning-hintbg' : '']" v-model="financeObj.prev2_new_amount" placeholder="1000.00" @input="financeObj.prev2_new_amount = checkFloat($event, financeObj.prev2_new_amount)" @blur="financeObj.prev2_new_amount = clearFloat(financeObj.prev2_new_amount)"></el-input>
							<span class="cdetail-namestyle">万元</span>
						</div>
						<span class="hint-msg">当年、上一年度及上上年度企业销售新产品实现的收入总额。</span>
						<span class="hint-msg">新产品是指指采用新技术原理、新设计构思研制、生产的全新产品，或在结构、材质、工艺等某一方面比原有产品有明显改进，从而显著提高了产品性能或扩大了使用功能的产品。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname"><span class="dis-block">新产品出口</span><span class="dis-block">销售额</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-input :class="['cdetail-iptstyle', 'getFinanceValue', financeTrueShow.newShowExportAmount ? 'warning-hintbg' : '']" v-model="financeObj.new_export_amount" placeholder="1000.00" @input="financeObj.new_export_amount = checkFloat($event, financeObj.new_export_amount)" @blur="financeObj.new_export_amount = clearFloat(financeObj.new_export_amount)"></el-input>
							万元
						</div>
						<span class="hint-msg">当年企业新产品出口所实现的销售收入。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname">营业收入</span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-input :class="['cdetail-iptstyle', 'getFinanceValue', financeTrueShow.operatingShowIncome ? 'warning-hintbg' : '', natEconomyObj.operatKeyIncome ? 'warning-hintbgblue' : '']" v-model="financeObj.operating_income" placeholder="1000.00" @input="financeObj.operating_income = checkMinusFloat($event, financeObj.operating_income)" @blur="financeObj.operating_income = clearMinusFloat(financeObj.operating_income)"></el-input>
							万元
						</div>
						<span class="hint-msg">当年企业营业收入。</span>
						<span class="hint-msg">营业收入，工业、建筑业、限额以上批发和零售业、限额以上住宿和餐饮业以及其他设置主营业务收入指标的行业，采用主营业务收入；限额以下批发与零售业企业采用商品销售额代替；限额以下住宿与餐饮业企业采用营业额代替；农、林、牧、渔业企业采用营业总收入代替；其他未设置主营业务收入的行业，采用营业收入指标。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname">利润总额</span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<span class="cdetail-namestyle">当年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getFinanceValue', financeTrueShow.curShowTotalProfit ? 'warning-hintbg' : '']" v-model="financeObj.cur_total_profit" placeholder="1000.00" @input="financeObj.cur_total_profit = checkMinusFloat($event, financeObj.cur_total_profit)" @blur="financeObj.cur_total_profit = clearMinusFloat(financeObj.cur_total_profit)"></el-input>
							<span class="cdetail-namestyle">万元，上一年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getFinanceValue', financeTrueShow.prev1ShowTotalProfit ? 'warning-hintbg' : '']" v-model="financeObj.prev1_total_profit" placeholder="1000.00" @input="financeObj.prev1_total_profit = checkMinusFloat($event, financeObj.prev1_total_profit)" @blur="financeObj.prev1_total_profit = clearMinusFloat(financeObj.prev1_total_profit)"></el-input>
							<span class="cdetail-namestyle">万元</span>
						</div>
						<span class="hint-msg">当年及上一年企业利润总额。</span>
						<span class="hint-msg">利润总额指企业在一定会计期间的经营成果，是生产经营过程中各种收入扣除各种耗费后的盈余，反映企业在报告期内实现的盈亏总额，根据损益表中“利润总额”项填写。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname">工业总产值</span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<span class="cdetail-namestyle">当年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getFinanceValue', financeTrueShow.curShowGrossOutput ? 'warning-hintbg' : '']" v-model="financeObj.cur_gross_output" placeholder="1000.00" @input="financeObj.cur_gross_output = checkFloat($event, financeObj.cur_gross_output)" @blur="financeObj.cur_gross_output = clearFloat(financeObj.cur_gross_output)"></el-input>
							<span class="cdetail-namestyle">万元，上一年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getFinanceValue', financeTrueShow.prev1ShowGrossOutput ? 'warning-hintbg' : '']" v-model="financeObj.prev1_gross_output" placeholder="1000.00" @input="financeObj.prev1_gross_output = checkFloat($event, financeObj.prev1_gross_output)" @blur="financeObj.prev1_gross_output = clearFloat(financeObj.prev1_gross_output)"></el-input>
							<span class="cdetail-namestyle">万元</span>
						</div>
						<span class="hint-msg">当年及上一年度企业工业总产值。</span>
						<span class="hint-msg">工业总产值是以货币形式表现的工业企业在一定时期内生产的已出售或可供出售工业产品总量。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname">综合能耗</span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<span class="cdetail-namestyle">当年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getFinanceValue', financeTrueShow.curShowEnergyConsumption ? 'warning-hintbg' : '']" v-model="financeObj.cur_energy_consumption" placeholder="5000.00" @input="financeObj.cur_energy_consumption = checkFloat($event, financeObj.cur_energy_consumption)" @blur="financeObj.cur_energy_consumption = clearFloat(financeObj.cur_energy_consumption)"></el-input>
							<span class="cdetail-namestyle">吨标准煤，上一年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getFinanceValue', financeTrueShow.prev1ShowEnergyConsumption ? 'warning-hintbg' : '']" v-model="financeObj.prev1_energy_consumption" placeholder="5000.00" @input="financeObj.prev1_energy_consumption = checkFloat($event, financeObj.prev1_energy_consumption)" @blur="financeObj.prev1_energy_consumption = clearFloat(financeObj.prev1_energy_consumption)"></el-input>
							<span class="cdetail-namestyle">吨标准煤</span>
						</div>
						<span class="hint-msg">当年及上一年度企业的主要生产系统、辅助生产系统和附属生产系统的综合能耗总和。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname">固定资产原价</span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-input :class="['cdetail-iptstyle', 'getFinanceValue', financeTrueShow.fixedShowAssets ? 'warning-hintbg' : '']" v-model="financeObj.fixed_assets" placeholder="1000.00" @input="financeObj.fixed_assets = checkFloat($event, financeObj.fixed_assets)" @blur="financeObj.fixed_assets = clearFloat(financeObj.fixed_assets)"></el-input>
							万元
						</div>
						<span class="hint-msg">当年年末企业固定资产原价。</span>
						<span class="hint-msg">固定资产原价指固定资产的成本，包括企业在购置、自行建造、安装、改建、扩建、技术改造某项固定资产时所发生的全部支出总额。可根据会计“固定资产”科目的期末借方余额填报。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname">资产总额</span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-input :class="['cdetail-iptstyle', 'getFinanceValue', financeTrueShow.totalShowAssets ? 'warning-hintbg' : '', natEconomyObj.totalKeyAssets ? 'warning-hintbgblue' : '']" v-model="financeObj.total_assets" placeholder="1000.00" @input="financeObj.total_assets = checkMinusFloat($event, financeObj.total_assets)" @blur="financeObj.total_assets = clearMinusFloat(financeObj.total_assets)"></el-input>
							万元
						</div>
						<span class="hint-msg">当年年末企业资产总额。</span>
						<span class="hint-msg">资产总额指企业过去的交易或者事项形成的、由企业拥有或者控制的、预期会给企业带来经济利益的资源。可根据会计“资产负债表”中“资产总计”项目的期末余额数填报。</span>
					</div>
				</div>
			</div>
		</div>
	<!-- </div> -->
</template>

<script>
	export default {
		data(){
			return{
				natEconomyObj: {
					operatKeyIncome: false,
					totalKeyAssets: false,
				}
			}
		},
		props:{
			financeObj: {
				type: Object,
				default() {
					return {}
				}
			},
			financeTrueShow: {
				type: Object,
				default() {
					return {}
				}
			},
			adminTypeClassfy: {
				type: String,
				default() {
					return ''
				}
			},
		},
		components:{
	
		},
		created() {
			
		},
		mounted() {
			
		},
		updated() {
			this.$parent.$parent.$parent.isHaveNull()
		},
		methods: {
			// 判断选择的是哪一个行业类型,判断是否缺失关键数据并作出提示
			noKeyData() {
				// console.log(this.financeObj)
				let emerIndusDatas = []
				if(this.adminTypeClassfy == '战略性新兴产业分类') { // 国民经济行业分类
					console.log(this.adminObj)
					emerIndusDatas = []
					this.natEconomyObj.operatKeyIncome = false // 营业收入
					this.natEconomyObj.totalKeyAssets = false // 资产总额
					return emerIndusDatas
				}else{
					if(this.financeObj.operating_income) {
						emerIndusDatas.push(this.financeObj.operating_income)
						this.natEconomyObj.operatKeyIncome = false
						// employee_count: '', // 职工总数  operating_income: '', // 营业收入  total_assets: '', // 资产总额
					}else{
						this.natEconomyObj.operatKeyIncome = true
					}
					if(this.financeObj.total_assets) {
						emerIndusDatas.push(this.financeObj.total_assets)
						this.natEconomyObj.totalKeyAssets = false
					}else{
						this.natEconomyObj.totalKeyAssets = true
					}
					console.log(this.natEconomyObj)
					return emerIndusDatas
				}
			},
			// input控制输入内容
			checkNumber(e, val) { // 输入整数
				this.$forceUpdate()
				// if(val) {
					// console.log(val)
					// val = val.replace(/^0/g, '')
					// val = val.replace(/^0/g, "") // 必须保证第一个为数字而不是0
					// val = (val.match(/^\d*/g)[0]) || null
					val = (val.match(/^\d{0,7}/g)[0]) || null
					return val
				// }
			},
			clearNumber(val) { // 保留小数点后两位，不满两位则补零
				this.$parent.$parent.$parent.verifyFinanceValue()
				if(val) {
					return parseInt(val)
				}else{
					return val
				}
			},
			checkFloat(e, val) { // 最多输入两位小数
				this.$forceUpdate()
				// if(val) {
					val = val.toString()
					val = val.replace(/^\./g, "") // 必须保证第一个为数字而不是点
					// val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || null
					val = (val.match(/^\d{0,9}\.\d\d|^\d{0,9}\.\d|^\d{0,9}\.|^\d{0,9}/g)[0]) || null
				// }
				return val
			},
			clearFloat(val) { // 保留小数点后两位，不满两位则补零
				this.$parent.$parent.$parent.verifyFinanceValue()
				if(val) {
					val = val.toString()
					if(val.indexOf(".") < 0) {
						val = val + ".00"
					}else if(val.indexOf(".") > 0) {
						let decimalNum = val.split('.')[1]
						if(decimalNum != '') {
							if(decimalNum.length== 1) {
								val = val + "0"
							}
						}else {
							val = val + "00"
						}
					}
					// return val
					return parseFloat(val).toFixed(2)
				}else{
					return val
				}
			},
			// 利润总额-可以为负数
			checkMinusFloat(e, val) { // 最多输入两位小数
				this.$forceUpdate()
				val = val.toString()
				val = val.replace(/^\./g, "") // 必须保证第一个为数字而不是点
				// val = (val.match(/^(-{0,1})\d*(\.?\d{0,2})/g)[0]) || null
				val = (val.match(/^(-{0,1})\d{0,9}\.\d\d|^(-{0,1})\d{0,9}\.\d|^(-{0,1})\d{0,9}\.|^(-{0,1})\d{0,9}/g)[0]) || null
				return val
			},
			clearMinusFloat(val) { // 保留小数点后两位，不满两位则补零
				this.$parent.$parent.$parent.verifyFinanceValue()
				if(val) {
					val = val.toString()
					if(val.indexOf(".") < 0) {
						if(val.length <= 1 && val.indexOf("-") == 0) {
							val = "0.00"
						}else{
							val = val + ".00"
						}
					}else if(val.indexOf(".") > 0) {
						let decimalNum = val.split('.')[1]
						if(decimalNum != '') {
							if(decimalNum.length== 1) {
								val = val + "0"
							}
						}else {
							val = val + "00"
						}
					}
					return parseFloat(val).toFixed(2)
				}else{
					return val
				}
			},
		},
		watch: {
			
		}
	}
</script>

<style scoped>
	/* tabs内容 */
	.contle-ctabs /deep/ .el-tabs__content{padding: 0;}
	.cpane-combox{padding: 20px;box-sizing: border-box;background: #e4e4e4;}
	.cpane-admincon{text-align: left;}
	.combox-contitle{position: relative;padding-left: 35px;line-height: 30px;color: #000000;font-weight: 700;font-size: 22px;}
	.combox-contitle .icon-title{position: absolute;left: 0;font-size: 22px;}
	.combox-contitle .icon-fintitle{position: absolute;left: 0;font-size: 26px;}
	.combox-conmain{}
	.conmain-mainitem{position: relative;margin-top: 40px;}
	.conmain-mainitem:first-child{margin-top: 50px;}
	.conmain-mainitem::after{display: block;content: '';clear: both;}
	.mainitem-itemname{position: absolute;left: 0;width: 120px;line-height: 30px;text-align: right;color: #000000;font-weight: 700;font-size: 20px;}/*input框标题字号*/
	.dis-block{display: block;}
	.dis-block:last-child{margin-top: -5px;}
	.mainitem-itemdetail{position: relative;margin-left: 130px;}
	.itemdetail-cdetail{line-height: 30px;font-size: 16px;}
	.itemdetail-cdetail::after{display: block;content: '';clear: both;}
	.cdetail-iptbox{display: block;float: left;margin-right: 10px;width: 300px;}
	.cdetail-iptbox /deep/ .el-input__inner{padding: 0 10px;height: 30px;line-height: 30px;border-radius: 0;font-size: 16px;}
	.cdetail-iptkeyword{display: block;float: left;margin-right: 5px;width: 200px;}
	.cdetail-iptkeyword /deep/ .el-input__inner{padding: 0 10px;height: 30px;line-height: 30px;border-radius: 0;font-size: 16px;}
	.cdetail-cdetinput::after{display: block;content: '';clear: both;}
	/* 点击“+”号添加更多box-------------- */
	.cdetail-addbox{display: block;float: left;}
	.cdetail-addbox::after{display: block;content: '';clear: both;}
	.addbox-multiple{margin-bottom: 10px;}
	.addbox-multiple:last-child{margin-bottom: 10px;}
	.addbox-multiple::after{display: block;content: '';clear: both;}
	/* 点击“+”号添加更多box-------------- */
	.cdetail-iptstyle{display: block;float: left;margin-right: 10px;width: 300px;}
	.cdetail-iptstyle /deep/ .el-input__inner{padding: 0 10px;height: 30px;line-height: 30px;border-radius: 0;font-size: 16px;}
	.warning-hintbg /deep/ .el-input__inner{background: rgb(255, 204, 204);}
	.warning-hintbgblue /deep/ .el-input__inner{background: rgb(153, 180, 203);}
	.cdetail-iptstyle .el-input__inner::focus{border: 1px solid #409eff;background: #fff !important;}
	.icon-search{display: block;float: left;width: 60px;height: 30px;line-height: 30px;font-size: 24px;}
	.input-w150{width: 150px;}
	.input-w250{width: 250px;}
	.cdetail-iptsunit{display: block;float: left;margin-right: 20px;}
	.cdetail-radiobox{display: block;float: left;margin-right: 10px;}
	/* .cdetail-radiobox /deep/ .el-radio-button{background: transparent;} */
	.cdetail-radiobox /deep/ .el-radio-button__inner{padding: 0 30px;height: 30px;line-height: 30px;border: 1px solid rgb(204, 204, 204);background: transparent;box-sizing: border-box;border-radius: 0;color:rgb(0, 0, 0);font-size: 16px;}
	.cdetail-radiobox /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner{background: rgb(241, 194, 50);border-color: rgb(241, 194, 50);box-shadow: -1px 0 0 0 rgb(241, 194, 50);}
	.cdetail-marketsector{display: block;float: left;margin-right: 10px;width: 180px;}
	.cdetail-industrybox{width: 400px;}
	.cdetail-marketsector /deep/ .el-input__inner{height: 30px;line-height: 30px;border-radius: 0;font-size: 16px;}
	.cdetail-marketsector /deep/ .el-input__icon{height: 30px;line-height: 30px;font-size: 16px;}
	.cdetail-iconstyle{display: block;float: left;line-height: 30px;font-size: 22px;cursor: pointer;}
	
	/* el-radio-button is-active */
	/* 提示 */
	.hint-msg{margin-top: 5px;display: block;color: #999999;font-weight: 400;font-size: 16px;}
	.hint-msg:first-child{margin-top: 5px;}
	.hint-red{color: rgb(255, 0, 0);}
	/* border: 1px solid #DCDFE6;box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%); */
	
	/* 财务类 */
	.cpane-adminfinance{}
	.w-140{width: 140px;}
	.cdetail-namestyle{display: block;float: left;margin: 0 10px 0 0;}
	.cdetail-namestyle:last-child{margin: 0;}
</style>
