<template>
  <!-- <div> -->
  <div class="cpane-combox cpane-admincon">
    <div class="combox-contitle"><i class="icon-briefcase-full iconfont icon-title"></i>行政类数据</div>
    <div class="combox-conmain">
      <!-- <el-form :model="adminObj" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"> -->
      <!-- :show-message="false"不显示错误信息，只显示红框-->
      <div class="conmain-mainitem">
        <span class="mainitem-itemname">所属行业</span>
        <div class="mainitem-itemdetail mainitem-itemindustry">
          <div class="itemdetail-cselect">
            <el-radio-group class="cselect-cradiogroup"
                            v-model="classifyRadio"
                            @change="industryChange">
              <el-radio class="cradiogroup"
                        label="国民经济行业分类"
                        :border="false"></el-radio>
              <el-radio class="cradiogroup"
                        label="战略性新兴产业分类"
                        :border="false"></el-radio>
            </el-radio-group>
          </div>
          <div class="itemdetail-cdetail">
            <div class="cdetail-cdselectbox">
              <el-select :class="['cdetail-marketsector', 'cdetail-industrybox', natEconomyObj.industryKeyVal ? 'warning-hintbgblue' : '']"
                         ref="selects"
                         v-if="classifyRadio=='国民经济行业分类'"
                         v-model="typeIndustryVal1"
                         @change="indChange"
                         placeholder="输入所属行业">
                <el-option-group v-for="(group, index) in indDatas"
                                 :key="index">
                  <el-option v-for="item in group.options"
                             :key="item.id"
                             :label="item.id + ' ' + item.name"
                             :value="item.name">
                  </el-option>
                </el-option-group>
              </el-select>
              <el-select :class="['cdetail-marketsector', 'cdetail-industrybox', natEconomyObj.industryKeyVal ? 'warning-hintbgblue' : '']"
                         ref="selects"
                         v-else
                         v-model="typeIndustryVal2"
                         @change="indChange"
                         placeholder="输入所属行业">
                <!--adminObj.industry-->
                <el-option-group v-for="(group, index) in indDatas"
                                 :key="index">
                  <el-option v-for="item in group.options"
                             :key="item.id"
                             :label="item.id + ' ' + item.name"
                             :value="item.name">
                  </el-option>
                </el-option-group>
              </el-select>
            </div>
            <el-input class="cdetail-iptkeyword"
                      v-model="indKeyWord"
                      @keyup.enter.native="keyWordSearch"
                      placeholder="使用关键词搜索"></el-input>
            <i class="icon-search iconfont icon-search"
               @click="keyWordSearch"></i>
          </div>
          <span class="hint-msg">企业的所属行业，可按照国民经济行业分类或战略性新兴产业分类来选择。注意这两个分类标准只能选择其一，以最终停留并确认提交的分类结果为准。</span>
          <span class="hint-msg">企业业务涉及多个行业分类的，请填写企业技术创新活动最为集中的分类。</span>
          <span class="hint-msg">所属行业对测评结果的准确性有较大影响，请谨慎填写。</span>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname">所属省份</span>
        <div class="mainitem-itemdetail"
             style="position:relative">
          <div class="itemdetail-cdetail">
            <!-- <el-form-item label="" prop="employee_count"> -->
            <!-- <el-input class="cdetail-iptstyle getAdminValue" v-model.number="adminObj.employee_count" placeholder="100"></el-input> -->
            <el-input :class="['cdetail-iptstyle', 'getAdminValue', adminTrueShow.devShowCount ? 'warning-hintbg' : '']"
                      v-model="adminObj.province"
                      readonly
                      @click.native="showMatrix"
                      @blur="showMatrix"
                      placeholder="北京"></el-input>
            <!-- </el-form-item> -->
          </div>
          <span class="hint-msg">企业注册地所归属的省（自治区、直辖市）。请按照最新情况做出选择。</span>
          <ul class="province-ul"
              v-show="provinced">
            <li v-for="item in provinceList"
                :key="item._id"
                :data-id="item._id"
                @mousedown="enterProvince(item)">{{item.value}}</li>
          </ul>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname">职工总数</span>
        <div class="mainitem-itemdetail">
          <div class="itemdetail-cdetail">
            <!-- <el-form-item label="" prop="employee_count"> -->
            <!-- <el-input class="cdetail-iptstyle getAdminValue" v-model.number="adminObj.employee_count" placeholder="100"></el-input> -->
            <el-input :class="['cdetail-iptstyle', 'getAdminValue', adminTrueShow.employeeShowCount ? 'warning-hintbg' : '', natEconomyObj.employeeKeyCount ? 'warning-hintbgblue' : '']"
                      v-model="adminObj.employee_count"
                      placeholder="100"
                      @input="adminObj.employee_count = checkNumber($event, adminObj.employee_count)"
                      @blur="adminObj.employee_count = clearNumber($event, adminObj.employee_count)"></el-input>
            <!-- </el-form-item> -->
            人
          </div>
          <span class="hint-msg">当年年末企业职工总数，含在职、兼职和临时聘用人员，其中兼职、临时聘用人员全年须在企业累计工作183天以上。</span>
          <span class="hint-msg hint-red">【注意】“当年”指企业前1个会计年度，下同。比如当前填写日期为2021年11月10日，则“当年”指2020.1.1~2020.12.31。</span>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname">研发人数</span>
        <div class="mainitem-itemdetail">
          <div class="itemdetail-cdetail">
            <!-- <el-input class="cdetail-iptstyle getAdminValue" v-model="adminObj.dev_count" placeholder="100" @input="adminObj.dev_count = checkNumber($event, adminObj.dev_count)" @blur="adminObj.dev_count = clearNumber(adminObj.dev_count)"></el-input> -->
            <el-input :class="['cdetail-iptstyle', 'getAdminValue', adminTrueShow.devShowCount ? 'warning-hintbg' : '']"
                      v-model="adminObj.dev_count"
                      placeholder="100"
                      @input="adminObj.dev_count = checkNumber($event, adminObj.dev_count)"
                      @blur="adminObj.dev_count = clearNumber($event, adminObj.dev_count)"></el-input>
            人
          </div>
          <span class="hint-msg">当年年末企业的研发人员数量。</span>
          <span class="hint-msg">研发人员指企业内部从事研发活动的人员，包括直接参加研发项目活动的人员，研发项目管理人员，以及为研发活动提供资料文献、材料供应、设备维护等直接服务的人员。其中兼职、临时聘用研发人员全年须在企业累计工作183天以上。</span>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname"><span class="dis-block">研发人员</span><span class="dis-block">薪酬收入</span></span>
        <div class="mainitem-itemdetail">
          <div class="itemdetail-cdetail">
            <el-input :class="['cdetail-iptstyle', 'getAdminValue', adminTrueShow.devShowPayment ? 'warning-hintbg' : '']"
                      v-model="adminObj.dev_payment"
                      placeholder="1000.00"
                      @input="adminObj.dev_payment = checkFloat($event, adminObj.dev_payment)"
                      @blur="adminObj.dev_payment = clearFloat(adminObj.dev_payment)"></el-input>
            <!--@blur.native.capture-->
            万元
          </div>
          <span class="hint-msg">当年企业研发人员薪酬总收入，含月度薪资、项目奖金、年底分红等。</span>
        </div>
      </div>
      <div class="conmain-mainitem">
        <span class="mainitem-itemname"><span class="dis-block">职工薪酬</span><span class="dis-block">总收入</span></span>
        <div class="mainitem-itemdetail">
          <div class="itemdetail-cdetail">
            <el-input :class="['cdetail-iptstyle', 'getAdminValue', adminTrueShow.employeeShowPayment ? 'warning-hintbg' : '']"
                      v-model="adminObj.employee_payment"
                      data-dotrange="{2}"
                      placeholder="1000.00"
                      @input="adminObj.employee_payment = checkFloat($event, adminObj.employee_payment)"
                      @blur="adminObj.employee_payment = clearFloat(adminObj.employee_payment)"></el-input>
            万元
          </div>
          <span class="hint-msg">当年企业全部职工的薪酬总收入，含月度薪资、项目奖金、年底分红等。</span>
        </div>
      </div>
      <!-- </el-form> -->
    </div>
  </div>

  <!-- </div> -->
</template>

<script>
export default {
  data () {
    var empCheckCount = (rule, value, callback) => {
      console.log(value)
      // if (!value) {
      // 	return callback(new Error('年龄不能为空'));
      // }
      if (value) {
        // setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'));
          // this.adminObj.employee_count = ''
          this.adminObj.employee_count = (value.match(/^\d*/g)[0]) || null
        } else {
          if (value < 0) {
            callback(new Error('必须是正整数'));
            this.adminObj.employee_count = ''
          } else {
            callback();
          }
        }
        // }, 100);
      }
    };
    return {
      indKeyWord: '', // 所属行业-关键词搜索
      indDatas: [ // 行业列表
        // {id:'', text: '全部行业'},
        // {id:'保险业', text: '保险业'},
      ],
      rules: {
        employee_count: [
          { validator: empCheckCount, trigger: 'blur' }
        ]
      },
      classifyRadio: '国民经济行业分类', // 所属行业分类

      natEconomyObj: { // 是否缺失关键字，如果缺失关键字，设置底框颜色提示
        industryKeyVal: false,
        employeeKeyCount: false
      },

      typeIndustryVal1: '', // 国民经济分类--选中的所属行业
      typeIndustryVal2: '', // 战略新兴产业--选中的所属行业
      // 是否展示所有省份框
      provinced: false,
      // 所有的省市
      provinceList: []
    }
  },
  props: {
    adminObj: {
      type: Object,
      default () {
        return {}
      }
    },
    adminTrueShow: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  components: {

  },
  created () {
    this.getProvince()
    this.keyWordSearch() // 关键字搜索获取行业列表
  },
  mounted () {
    // console.log(this.$parent)
    // this.$parent.$parent.$parent.isHaveNull()
  },
  updated () {
    // console.log(this.$parent)
    this.$parent.$parent.$parent.isHaveNull()
  },
  methods: {
    getProvince () {
      this.$axios({
        url: '/company/find/province',
        method: 'get'
      }).then(res => {
        let { code, data } = res.data
        if (code == 200) {
          this.provinceList = data
          // this.provinceList.forEach(item => {
          //   if (item.value == this.adminObj.province) {
          //     this.adminObj.province = item._id
          //     this.adminObj.provinceName = item.value
          //   }
          // })
        } else {
          this.provinceList = []
        }
      })
    },
    industryChange () { // 所属行业类型选择
      console.log(this.classifyRadio)
      console.log(this.adminObj.industry)
      this.keyWordSearch() // 关键字搜索获取行业列表
      // this.adminObj.industryType = this.classifyRadio
      if (this.classifyRadio == '战略性新兴产业分类') {
        // this.adminObj.industryType = '战略性新兴产业分类'
        this.adminObj.industry = this.typeIndustryVal2
      } else {
        // this.adminObj.industryType = '国民经济行业分类'
        this.adminObj.industry = this.typeIndustryVal1
      }
      console.log('国民经济-' + this.typeIndustryVal1 + '----战略性新兴-' + this.typeIndustryVal2)
      console.log(this.adminObj.industry)
      // this.adminObj.industry = ''
      // this.noKeyData()
    },
    // 判断选择的是哪一个行业类型,判断是否缺失关键数据并作出提示
    noKeyData () {
      if (this.classifyRadio == '战略性新兴产业分类') { // 国民经济行业分类
        this.adminObj.industry = this.typeIndustryVal2
        console.log(this.adminObj)
        let natEconomyDatas = []
        this.natEconomyObj.employeeKeyCount = false // 职工总数
        if (this.adminObj.industry) { // 所属行业 // 如果有关键数据，则为false，反之无数据，则为true,设置底色红色显示
          natEconomyDatas.push(this.adminObj.industry)
          this.natEconomyObj.industryKeyVal = false
        } else {
          this.natEconomyObj.industryKeyVal = true
        }
        console.log(this.natEconomyObj)
        return natEconomyDatas
      } else {
        this.adminObj.industry = this.typeIndustryVal1
        // console.log(this.adminObj)
        let emerIndusDatas = []
        if (this.adminObj.employee_count) { // 职工总数
          emerIndusDatas.push(this.adminObj.employee_count)
          this.natEconomyObj.employeeKeyCount = false
          // employee_count: '', // 职工总数  operating_income: '', // 营业收入  total_assets: '', // 资产总额
        } else {
          this.natEconomyObj.employeeKeyCount = true
        }
        if (this.adminObj.industry) { // 所属行业
          emerIndusDatas.push(this.adminObj.industry)
          this.natEconomyObj.industryKeyVal = false
        } else {
          this.natEconomyObj.industryKeyVal = true
        }
        console.log(this.natEconomyObj)
        return emerIndusDatas
      }
    },
    indChange (val) { // 获取所属行业
      console.log(val + '============' + this.adminObj.industry)
    },
    keyWordSearch () { // 所属行业-关键字搜索
      let _this = this
      let industryType = _this.classifyRadio == '战略性新兴产业分类' ? 2 : 1 // 行业类型 1-国民经济行业分类；2-战略性新兴产业分类
      console.log('行业分类-' + industryType)
      console.log('industryType - ' + industryType)
      let qsData = _this.$qs.stringify({
        industry: _this.indKeyWord,
        industryType: industryType,
      });
      _this.$axios({
        method: 'post',
        url: '/company/online/find/industry',
        data: qsData,
        // baseUrl: 'http://192.168.2.62:997',
        // baseUrl: _this.urlCommon,
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      }).then(function (res) {
        // console.log(res)
        // console.log(res.data.code == 200)
        if (res.data.code == 401) {
          _this.$parent.$parent.$parent.goLoginPopupShow = true
          return
        }
        if (res.data.code == 200) {
          _this.indDatas = res.data.data
          console.log(_this.indDatas)
          if (_this.indKeyWord) {
            _this.$refs.selects.$el.click()
          }
        } else {
          _this.indDatas = []
        }
      }).catch(function (e) {
        console.log('error------------------------------error');
        console.log(e.response)
      });
    },

    // input控制输入内容
    // checkNumber(e, val) { // 输入整数
    // 	this.$forceUpdate()
    // 	console.log(e)
    // 	console.log(val)
    // 	let reg = /^[0-9]([0-9])*$/
    // 	console.log(reg.test(val))
    // 	e.target.style.border = '1px solid #409eff'
    // 	if((!reg.test(val)) && (val != '')){
    // 		console.log('输入格式不符，设置成红框')
    // 		e.target.style.border = '1px solid red' // 输入格式有误,设置为红框
    // 	}else{
    // 		console.log('输入格式正确，设置成蓝框')
    // 		e.target.style.border = '1px solid #409eff'
    // 	}
    // 	val = (val.match(/^\d*/g)[0]) || null
    // 	return val
    // },
    // input控制输入内容
    checkNumber (e, val) { // 输入整数
      this.$forceUpdate()
      // console.log(e)
      // e.target.style.border = '1px solid red'
      // console.log(e.target.style.border)
      // if(val) {
      // console.log(val)
      // val = val.replace(/^0/g, '')
      // val = val.replace(/^0/g, "") // 必须保证第一个为数字而不是0
      // e.target.style.background = '#fff'
      val = (val.match(/^\d{0,7}/g)[0]) || null
      // val = (val.match(/^[1-9]([0-9]*)|^[0-9]/g)[0]) || null
      return val
      // }

    },
    clearNumber (e, val) { // 保留小数点后两位，不满两位则补零
      // console.log(e)
      // e.target.style.border = '1px solid #DCDFE6'
      this.$parent.$parent.$parent.verifyAdminValue()
      if (val) {
        // if(val >= 0 && val <= 99999) {
        // 	e.target.style.background = '#fff' // 输入格式有误,设置为红框
        // }else{
        // 	e.target.style.background = 'rgb(255, 204, 204)' // 输入格式有误,设置为红框
        // }
        return parseInt(val)
      } else {
        return val
      }
    },
    checkFloat (e, val) { // 最多输入两位小数
      // if(val) {
      this.$forceUpdate()
      val = val.toString()
      val = val.replace(/^\./g, "") // 必须保证第一个为数字而不是点
      // val = (val.match(/^\d{0,9}(\.?\d{0,2})/g)[0]) || null
      val = (val.match(/^\d{0,9}\.\d\d|^\d{0,9}\.\d|^\d{0,9}\.|^\d{0,9}/g)[0]) || null
      // console.log(val)
      // }
      return val
    },
    clearFloat (val) { // 保留小数点后两位，不满两位则补零
      // console.log(val)
      this.$parent.$parent.$parent.verifyAdminValue()
      if (val) {
        val = val.toString()
        if (val.indexOf(".") < 0) {
          val = val + ".00"
        } else if (val.indexOf(".") > 0) {
          let decimalNum = val.split('.')[1]
          if (decimalNum != '') {
            if (decimalNum.length == 1) {
              val = val + "0"
            }
          } else {
            val = val + "00"
          }
        }
        return parseFloat(val).toFixed(2)
        // return val
      } else {
        return val
      }
    },
    // 展示所有省份
    showMatrix () {
      this.provinced = !this.provinced
    },
    // 点击某个省份后
    enterProvince (item) {
      // this.provinceName = item.value
      this.adminObj.province = item.value
    }
  },
  watch: {
    adminObj: {
      handler (val) { // val, oldVal
        // console.log('当数量变化时重新绘制图表')//但是这两个值打印出来却都是一样的
        console.log(val)
        if (this.adminObj.industryType) {
          this.classifyRadio = this.adminObj.industryType
        }
        // if(this.adminObj.industry) {
        this.typeIndustryVal2 = ''
        this.typeIndustryVal1 = ''
        if (this.adminObj.industryType == '战略性新兴产业分类') {
          this.typeIndustryVal2 = this.adminObj.industry
        } else {
          this.typeIndustryVal1 = this.adminObj.industry
        }
        // }
        this.keyWordSearch()
        console.log(this.adminObj.industry)
        console.log('国民经济-' + this.typeIndustryVal1 + '----战略性新兴-' + this.typeIndustryVal2)
      }
    },
  }
}
</script>

<style>
/* tabs内容 */
.contle-ctabs /deep/ .el-tabs__content {
  padding: 0;
}
.cpane-combox {
  padding: 20px;
  box-sizing: border-box;
  background: #e4e4e4;
}
.cpane-admincon {
  text-align: left;
}
.combox-contitle {
  position: relative;
  padding-left: 350px;
  line-height: 30px;
  color: #000000;
  font-weight: 700;
  font-size: 22px;
}
.combox-contitle .icon-title {
  position: absolute;
  left: 0;
  font-size: 22px;
}
.combox-contitle .icon-fintitle {
  position: absolute;
  left: 0;
  font-size: 26px;
}
.combox-conmain {
}
.conmain-mainitem {
  position: relative;
  margin-top: 40px;
}
.conmain-mainitem:first-child {
  margin-top: 50px;
}
.conmain-mainitem::after {
  display: block;
  content: "";
  clear: both;
}
.mainitem-itemname {
  position: absolute;
  left: 0px;
  width: 120px;
  line-height: 30px;
  text-align: right;
  color: #000000;
  font-weight: 700;
  font-size: 20px;
} /*input框标题字号*/
.dis-block {
  display: block;
}
.dis-block:last-child {
  margin-top: -5px;
}
.mainitem-itemdetail {
  position: relative;
  margin-left: 130px;
}
.itemdetail-cdetail {
  line-height: 30px;
  font-size: 16px;
}
.itemdetail-cdetail::after {
  display: block;
  content: "";
  clear: both;
}
.cdetail-iptbox {
  display: block;
  float: left;
  margin-right: 10px;
  width: 300px;
}
.cdetail-iptbox .el-input__inner {
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  font-size: 16px;
}
.cdetail-iptkeyword {
  display: block;
  float: left;
  margin-right: 5px;
  width: 200px;
}
.cdetail-iptkeyword .el-input__inner {
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  font-size: 16px;
}
/* 校验输入框内容-样式 */
.itemdetail-cdetail .el-form-item {
  display: block;
  float: left;
  margin-bottom: 0;
}
.itemdetail-cdetail .el-form-item__content {
  margin-left: 0 !important;
  line-height: 30px;
}

/* 所属行业-分类 */
.mainitem-itemindustry {
  position: relative;
}
.itemdetail-cselect {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 40px;
}
/*  */
.itemdetail-cselect .el-radio-group {
}
.itemdetail-cselect .el-radio {
  margin: 0;
  width: 200px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(102, 102, 102);
  box-sizing: border-box;
  background: none;
  text-align: center;
  font-size: 16px;
}
.itemdetail-cselect .el-radio:first-child::after {
  content: "|";
  position: absolute;
  right: 0;
}
.itemdetail-cselect .el-radio.is-checked {
  border-bottom: 3px solid rgb(102, 204, 51);
}
.itemdetail-cselect .el-radio__input {
  display: none;
}
.itemdetail-cselect .el-radio__label {
  padding-left: 0;
  font-size: 16px;
}
.itemdetail-cselect .el-radio.is-checked .el-radio__label {
  color: rgb(102, 204, 51);
}

.cdetail-iptstyle {
  display: block;
  float: left;
  margin-right: 10px;
  width: 300px;
}
.cdetail-iptstyle .el-input__inner {
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  font-size: 16px;
}
.warning-hintbg .el-input__inner {
  background: rgb(255, 204, 204);
}
.warning-hintbgblue .el-input__inner {
  background: rgb(153, 180, 203);
}
.cdetail-iptstyle .el-input__inner::focus {
  border: 1px solid #409eff;
  background: #fff !important;
}
.icon-search {
  display: block;
  float: left;
  width: 60px;
  height: 30px;
  line-height: 30px;
  font-size: 24px;
}
.cdetail-hightech {
  display: block;
  float: left;
  margin-right: 10px;
}
.cdetail-hightech .el-radio-button__inner {
  padding: 0 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  font-size: 16px;
}
.cdetail-hightech
  .el-radio-button__orig-radio:checked
  + .el-radio-button__inner {
  background: rgb(102, 204, 51);
  border-color: rgb(102, 204, 51);
  box-shadow: -1px 0 0 0 rgb(102, 204, 51);
}
.cdetail-cdselectbox {
  display: block;
  float: left;
  margin-right: 10px;
  height: 30px;
  line-height: 30px;
}
/* .cdetail-marketsector{display: block;float: left;margin-right: 10px;} */
.cdetail-industrybox {
  width: 400px;
}
.cdetail-marketsector .el-input__inner {
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  border-radius: 0;
  font-size: 16px;
}
.cdetail-marketsector .el-input__suffix {
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
}
.cdetail-marketsector .el-input__icon {
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  font-size: 16px;
}
/* 提示 */
.hint-msg {
  margin-top: 5px;
  display: block;
  color: #999999;
  font-weight: 400;
  font-size: 16px;
}
.hint-msg:first-child {
  margin-top: 5px;
}
.hint-red {
  color: rgb(255, 0, 0);
}
/* border: 1px solid #DCDFE6;box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%); */

.cpane-tablemode {
  margin-top: 20px;
  overflow: hidden;
  text-align: left;
  color: rgb(0, 0, 0);
  font-size: 16px;
}
.tablemode-articledel {
  display: block;
  float: left;
  height: 30px;
  line-height: 30px;
}
.tablemode-operabtn {
  display: block;
  float: right;
  padding: 0;
  width: 100px;
  height: 30px;
  line-height: 28px;
  border: 1px solid #dcdfe6;
  background: #ffffff;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
}
.tablemode-download {
  margin-right: 10px;
}
/* 上传文件 */
.upload-upfilebox {
  position: relative;
}
.upfilebox-filebtn {
  display: block;
  width: 100px;
  height: 30px;
}
.upfilebox-filename {
}
.upfilebox-fileinput {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 30px;
  opacity: 0;
  z-index: 20;
  cursor: pointer;
}
/* 所有省份 */
.province-ul {
  width: 350px;
  position: absolute;
  top: 35px;
  z-index: 100;
  background: #ffffff;
  padding: 10px 2px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}
.province-ul > li {
  width: 25%;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
}
</style>
